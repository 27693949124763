<template>
	<div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background:var(--light-bg-gray); overflow:scroll"
		ref="scroller" @scroll="scrollingContainerShadow">
		<ContractTemplates :title="filter.label" :contracts="contracts" v-if="contracts.length" />

		<EmptyApp :app="$store.state.theApp" text="You don't have any Templates" v-else />
	</div>
</template>

<script>
import { scrollingContainerShadow } from 'bh-mod'
import EmptyApp from 'bh-mod/components/common/EmptyApp'
import ContractTemplates from '@/components/forms/ContractTemplates'

export default {
	components: {
		EmptyApp, ContractTemplates
	},
	computed: {
		filter() {
			return this.$store.state.appData.filter
		},
		contracts() {
			return Object.values(this.$store.state.appData.templates).filter(contract => {
				if (this.filter.value === 'archived') return contract.archived
				if (this.filter.value === 'all') return !contract.archived
				return contract.type === this.filter.value && !contract.archived
			})
		},
	},
	methods: {
		scrollingContainerShadow,
	},
	created() {
		this.$store.commit('PAGE_BUTTONS', [
			{
				label: 'ADD CONTRACT TEMPLATE',
				ant: 'plus',
				callback: () => this.$router.push('/template')
			},
		])
	},
}
</script>

<style lang="scss" scoped></style>
